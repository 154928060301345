<template>
  <v-row align-content="center" class="h-100" justify="center" no-gutters>
    <v-col cols="10">
      <div class="d-flex flex-column align-center">
        <div class="d-flex align-center mb-16">
          <img
            alt="Zoom"
            class="img-width"
            :src="require('@/assets/logos/suraasa-logo.svg')"
          />
          <div class="mx-10 loader-container">
            <v-progress-linear
              buffer-value="0"
              class="mb-4"
              color="primary"
              stream
            />
            <v-progress-linear
              buffer-value="0"
              color="primary"
              reverse
              stream
            />
          </div>
          <img
            alt="Zoom"
            class="img-width"
            :src="require('@/assets/logos/zoom-logo.png')"
          />
        </div>
        <p class="text-h5 mb-16">
          Authenticating with Zoom
        </p>
        <p class="text-body-1">
          If this page does not refresh in 10 seconds, please
          <router-link :to="{ name: 'classes' }">Go Back</router-link> and try
          again.
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import urls from "@/utils/urls"
import { setZoomAuthToken } from "@/utils/helpers"

export default {
  name: "AuthenticateZoom",

  data: () => ({
    authorizationCode: null,
  }),

  beforeMount() {
    this.authorizationCode = this.$route.query.code
    this.getAccessToken()
  },

  methods: {
    getAccessToken() {
      this.$http
        .post(urls.auth.getZoomAccessToken, {
          code: this.authorizationCode,
        })
        .then(response => {
          if (response.data.data) {
            setZoomAuthToken(response.data.data)

            this.$toast.info("Authenticated with zoom successfully!")

            this.$router.push({
              name: "classes",
            })
          }
        })
        .catch(error => {
          /**
           * This check is true when user's access token (NOT zoom access token) has expired.
           * In this case, we wait for axios interceptor to refresh the token and then refresh the page.
           * This will cause the beforeMount hook to call this function again.
           */
          if (error.response.status === 401) return

          try {
            if (error.response.data?.errors?.detail[0]) {
              this.$toast.error(error.response.data.errors.detail[0])
            }
            this.$router.push({
              name: "classes",
            })
          } catch {
            this.$toast.error(
              "Something went wrong! Please go back and try again"
            )
            throw error
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.h-100 {
  height: 100%;
}

.loader-container {
  width: 200px;
}

.img-width {
  width: 100px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .loader-container {
    width: 100px;
  }
  .img-width {
    width: 60px;
  }
}
</style>
